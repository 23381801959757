<template>
  <div class="compare-table view">
    <app-header :title="'Imported Items'"/>
    <progress-linear v-if="loading" :indeterminate="true"/>

    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{ __('common.total') }}:</div>
        <div class="control-bar__info-value">{{ rawImportedItems.length }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="currentIndex"
          :label="'currentIndex'"
          :type="'number'"
          :hide-details="true"
      />
      <div class="control-bar__buttons">
        <my-switch
            class="mr"
            v-model="industryOnly"
            label="Industry only"
        ></my-switch>
        <my-switch
            class="mr"
            v-model="ignoreIcon"
            label="Ignore icon"
        ></my-switch>
        <my-switch
            class="mr"
            v-model="useHasChangesProperty"
            label="Use has changes property"
        ></my-switch>
        <div class="control-bar__button" :title="'Delete all'" @click="deleteAll" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/bin.svg"
               alt="delete all"/>
        </div>
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow mt">
      <div class="max-width-page-big">
        <div class="tile">
          <div class="compare" v-if="currentIndex !== null">
            <div class="compare__progress">
              <div class="compare__progress-value"
                   :style="{width: `${(currentIndex + 1) * 100 / importedItems.length}%`}"></div>
              <div class="compare__progress-label">{{ currentIndex + 1 }} / {{ importedItems.length }}</div>
              <div class="compare__progress-prev" v-if="currentIndex > 0" @click="currentIndex--">
                <icon :icon="'chevron-left'"/>
              </div>
              <div class="compare__progress-next" v-if="currentIndex < importedItems.length -1" @click="currentIndex++">
                <icon :icon="'chevron-right'"/>
              </div>
            </div>
            <div class="compare__table mt">
              <div class="compare__table-row">
                <div class="compare__table-source compare__table-source--header">
                  Imported Item
                </div>
                <div class="compare__table-actions">
                  <div class="compare__table-action"
                       v-if="!currentItem && currentIndex !== null && currentIndex > -1"
                       @click="createItem(currentImported)"
                  >
                    <icon :icon="'add'"/>
                  </div>
                  <div class="compare__table-action"
                       v-if="currentItem && currentIndex !== null && currentIndex > -1"
                       @click="applyItem(currentImported, currentItem)"
                  >
                    <icon :icon="'chevron-right'"/>
                  </div>
                </div>
                <div class="compare__table-destination compare__table-destination--header">
                  Destination Item
                  <router-link v-if="currentItem" class="ml"
                               :to="{name: 'crafting_calculator', query: { selectedItemId: currentItem.id }}"
                               target="_blank">
                    <icon :icon="'open-in-new'"/>
                  </router-link>
                  <span v-else class="ml text--orbitron text--success">NEW</span>
                </div>
              </div>


              <div class="compare__table-row"
                   v-if="showPropertyDiff(property)"
                   v-for="property in Object.keys(currentImported)"
              >
                <div class="compare__table-source">
                  <div class="compare__table-property-name">{{ property }}</div>
                  <div class="compare__table-property-value">
                    <template v-if="property === 'industryItemRelations'">
                      <ul class="futuristic">
                        <li v-for="industryItemRelation in currentImported[property]"
                            v-html="getRawImportedItemFullName(industryItemRelation.industryItemId)"></li>
                      </ul>
                    </template>
                    <template v-else-if="property === 'recipeIngredients'">
                      <ul class="futuristic">
                        <li v-for="recipeIngredient in currentImported[property]">
                          <span v-html="getRawImportedItemFullName(recipeIngredient.ingredientItemId)"></span>:
                          {{ recipeIngredient.amount }}
                        </li>
                      </ul>
                    </template>
                    <template v-else-if="currentImported[property] !== null">
                      <span
                          :class="{'text--cyan': !currentItem || currentImported[property] !== currentItem[property]}">
                        <template v-if="property === 'name'">
                          {{ currentImported['fullName'] }}
                        </template>
                        <template v-else>
                          {{ currentImported[property] }}
                        </template>
                      </span>
                    </template>
                    <span v-else class="text--disabled">NULL</span>
                  </div>
                </div>


                <div class="compare__table-actions">
                  <div class="compare__table-action"
                       v-if="currentItem && currentImported[property] !== currentItem[property]"
                       @click="applyProperty(currentItem.id, currentItem.key, property, currentImported[property])"
                  >
                    <icon :icon="'chevron-right'"/>
                  </div>
                </div>
                <div class="compare__table-destination">
                  <div class="compare__table-property-name">{{ property }}</div>
                  <div class="compare__table-property-value">
                    <template v-if="property === 'industryItemRelations'">
                      <ul class="futuristic" v-if="currentItem">
                        <li v-for="industryItemRelation in currentItem[property]"
                            v-html="getRawItemFullName(industryItemRelation.industryItemId)"></li>
                      </ul>
                    </template>
                    <template v-else-if="property === 'recipeIngredients'">
                      <ul class="futuristic" v-if="currentItem">
                        <li v-for="recipeIngredient in currentItem[property]">
                          <span v-html="getRawItemFullName(recipeIngredient.ingredientItemId)"></span>:
                          {{ recipeIngredient.amount }}
                        </li>
                      </ul>
                    </template>
                    <template v-else-if="currentItem && currentItem[property] !== null">
                      <span :class="{'text--cyan': currentImported[property] !== currentItem[property]}">
                        <template v-if="property === 'name'">
                          {{ currentItem['fullName'] }}
                        </template>
                        <template v-else>
                          {{ currentItem[property] }}
                        </template>
                      </span>
                    </template>
                    <template v-else-if="!currentItem">
                      <span class="text--cyan">NULL</span>
                    </template>
                    <span v-else class="text--disabled">NULL</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiSecured from '../../../api/secured';
import AppHeader from '../../../components/AppHeader';
import { notificationsMixin } from '../../../mixins/notifications';
import { localizationMixin } from '../../../mixins/localization';
import { paginationMixin } from "../../../mixins/pagination";
import { pageTitleMixin } from "../../../mixins/pageTitle";
import Swal from "sweetalert2";

export default {
  mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
  components: {
    appHeader: AppHeader,
  },
  data: () => ({
    industryOnly: false,
    useHasChangesProperty: true,
    loading: false,
    importedItems: [],
    items: {},
    rawImportedItems: [],
    rawItems: {},
    currentIndex: -1,
    ignoreIcon: false,
    industryItemRelations: [],
    industryItemParsedRelations: [],
    recipeIngredients: [],
    recipeIngredientsParseds: [],

    skippedProperties: [
      'id',
      'botPrice',
      'createdAt',
      'fullName',
      'isReferenceVoxel',
      'needReview',
      'updatedAt',
      'resistancesAntimatterHp',
      'resistancesKineticHp',
      'resistancesThermicHp',
      'resistancesElectromagneticHp',
      'resistancesAntimatterHpPerMass',
      'resistancesKineticHpPerMass',
      'resistancesThermicHpPerMass',
      'resistancesElectromagneticHpPerMass',
      'iconInGamePath',
      'buyPrice',
      'sellPrice',
      'scannerColor',
      'hasChanges'
    ],
    forceVisibleProperties: [
      'name'
    ]
  }),
  watch: {
    ignoreIcon() {
      if (this.ignoreIcon && !this.skippedProperties.includes('icon')) {
        this.skippedProperties.push('icon');
        this.prepareRealDifferences();
      } else if (!this.ignoreIcon && this.skippedProperties.includes('icon')) {
        const index = this.skippedProperties.findIndex(prop => prop === 'icon');
        this.skippedProperties.splice(index, 1);
        this.prepareRealDifferences();
      }
    },
    industryOnly() {
      this.prepareRealDifferences();
    },
    useHasChangesProperty() {
      this.prepareRealDifferences();
    }
  },
  computed: {
    currentImported() {
      const current = this.importedItems[this.currentIndex];
      if (!current) {
        return {};
      }
      return current;
    },
    currentItem() {
      if (!this.currentImported) {
        return null;
      }
      return this.items[this.currentImported.key];
    },
    rawItemsArray() {
      return Object.keys(this.rawItems).map(key => {
        return this.rawItems[key];
      });
    }
  },
  methods: {
    showPropertyDiff(property) {
      if (this.currentImported) {
        if (this.forceVisibleProperties.includes(property)) {
          return true;
        }
        if (!this.currentItem) {
          return true;
        }
        if(!this.skippedProperties.includes(property)) {
          if (property === 'industryItemRelations') {
            return this.getHasIndustryItemRelationDiff(this.currentImported, this.currentItem);
          } else if (property === 'recipeIngredients') {
            return this.getHasRecipeIngredientsDiff(this.currentImported, this.currentItem);
          } else {
            return this.currentImported[property] !== this.currentItem[property];
          }
        }
      }
      return false;
    },
    getRawImportedItemFullName(id) {
      const item = this.rawImportedItems.find(item => item.id === id);
      if (item) {
        return item.fullName;
      }
      return '<span class="text--error">MISSING</span>';
    },
    getRawItemFullName(id) {
      const item = this.rawItemsArray.find(item => item.id === id)
      if (item) {
        return item.fullName;
      }
      return '<span class="text--error">MISSING</span>';
    },
    getHasIndustryItemRelationDiff(importedItem, item) {
      if (!item) {
        return true;
      }
      if (importedItem.industryItemRelations.length !== item.industryItemRelations.length) {
        return true;
      }
      const importedKeys = importedItem.industryItemRelations.map(iir => iir.industryItem.key);
      const keys = item.industryItemRelations.map(iir => iir.industryItem.key);
      let hasDiff = false;
      importedKeys.forEach(importedKey => {
        if(!keys.includes(importedKey)) {
          hasDiff = true;
        }
      });
      return hasDiff;
    },
    getHasRecipeIngredientsDiff(importedItem, item) {
      if (!item) {
        return true;
      }
      if (importedItem.recipeIngredients.length !== item.recipeIngredients.length) {
        return true;
      }
      let hasDiff = false;
      importedItem.recipeIngredients.forEach(importedRecipeIngredient => {
        let itemIngredient = item.recipeIngredients.find(ri => ri.ingredientItem.key === importedRecipeIngredient.ingredientItem.key)
        if (!itemIngredient || itemIngredient.amount !== importedRecipeIngredient.amount) {
          hasDiff = true;
        }
      });
      return hasDiff;
    },
    deleteAll() {
      Swal.fire({
        title: this.__('common.are_you_sure'),
        text: this.__('common.do_you_want_to_delete'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.__('common.yes_delete_it')
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          apiSecured.delete('/ingame/items/itemparser/deleteall').then((res) => {
            this.loading = false;
            this.showInfoNotification(this.__('common.deleted'));
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
        }
      });
    },
    async refresh() {
      this.loading = true;
      await apiSecured.get('/ingame/items/itemparser/comparison-data').then(async (res) => {
        this.rawImportedItems = res.data.importedItems;
        this.rawItems = {};
        res.data.items.forEach(rec => {
          this.rawItems[rec.key] = rec;
        });
        this.loading = false;
        this.showInfoNotification('parsed ' + this.__('common.items_loaded'));
        this.currentIndex = 0;
        this.prepareRealDifferences();
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    prepareRealDifferences() {
      this.importedItems = [];
      this.items = {};
      this.rawImportedItems.forEach(importedItem => {
        let hasDiff = false;
        if (!this.industryOnly || importedItem.key.toLowerCase().startsWith('industry')) {
          if(this.useHasChangesProperty) {
            hasDiff = importedItem.hasChanges;
          } else {
            Object.keys(importedItem).forEach(property => {
              if (property === 'industryItemRelations') {
                if (this.getHasIndustryItemRelationDiff(importedItem, this.rawItems[importedItem.key])) {
                  hasDiff = true;
                }
              } else if (property === 'recipeIngredients') {
                if (this.getHasRecipeIngredientsDiff(importedItem, this.rawItems[importedItem.key])) {
                  hasDiff = true;
                }
              } else {
                if (!this.skippedProperties.includes(property)) {
                  if (!Object.keys(this.rawItems).includes(importedItem.key)) {
                    hasDiff = true;
                  } else {
                    if (this.rawItems[importedItem.key]) {
                      if (importedItem[property] !== this.rawItems[importedItem.key][property]) {
                        hasDiff = true;
                      }
                    }
                  }
                }
              }
            });
          }
        }
        if (hasDiff) {
          this.importedItems.push(importedItem);
          this.items[importedItem.key] = this.rawItems[importedItem.key];
        }
      });
    },
    async applyProperty(id, key, property, newValue) {
      if (this.loading) {
        return;
      }
      const payload = [];
      if (property === 'industryItemRelations') {
        let itemKeys = newValue.map(iir => iir.industryItem.key);
        payload.push({
          op: 'replace',
          path: `/${property}`,
          value: itemKeys
        });
      } else if (property === 'recipeIngredients') {
        let ingredients = {};
        newValue.forEach(ri => {
          ingredients[ri.ingredientItem.key] = ri.amount;
        })
        payload.push({
          op: 'replace',
          path: `/${property}`,
          value: ingredients
        });
      } else {
        payload.push({
          op: 'replace',
          path: `/${property}`,
          value: newValue
        });
      }

      this.loading = true;
      await apiSecured.patch(`/ingame/items/item/${id}`, payload).then(async (res) => {
        this.loading = false;
        this.$forceUpdate();
        this.showSuccessNotification(this.__('common.saved'));
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    },

    async applyItem(importedItem, item) {
      if (this.loading) {
        return;
      }
      const payload = [];

      Object.keys(importedItem).forEach(property => {
        if (!this.skippedProperties.includes(property)) {
          if (property === 'industryItemRelations') {
            if(this.getHasIndustryItemRelationDiff(importedItem, item)) {
              let itemKeys = importedItem[property].map(iir => iir.industryItem.key);
              payload.push({
                op: 'replace',
                path: `/${property}`,
                value: itemKeys
              });
            }
          } else if (property === 'recipeIngredients') {
            if(this.getHasRecipeIngredientsDiff(importedItem,item)) {
              let ingredients = {};
              importedItem[property].forEach(ri => {
                ingredients[ri.ingredientItem.key] = ri.amount;
              })
              payload.push({
                op: 'replace',
                path: `/${property}`,
                value: ingredients
              });
            }
          } else {
            if (importedItem[property] !== item[property]) {
              payload.push({
                op: 'replace',
                path: `/${property}`,
                value: importedItem[property],
              });
            }
          }
        }
      });

      this.loading = true;
      await apiSecured.patch(`/ingame/items/item/${item.id}`, payload).then(async (res) => {
        this.loading = false;
        this.$forceUpdate();
        this.showSuccessNotification(this.__('common.saved'));
        if (this.currentIndex < this.importedItems.length - 1) {
          this.currentIndex++;
        }
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    },
    async createItem(importedItem) {
      if (this.loading) {
        return;
      }
      const payload = {};
      Object.keys(importedItem).forEach(property => {
        if (property === 'industryItemRelations') {
          let itemKeys = [];
          importedItem[property].forEach(iir => {
            itemKeys.push(this.rawImportedItems.find(item => item.id === iir.industryItemId).key);
          })
          payload[property] = itemKeys;
        } else if (property === 'recipeIngredients') {
          let ingredients = {};
          importedItem[property].forEach(ri => {
            ingredients[this.rawImportedItems.find(item => item.id === ri.ingredientItemId).key] = ri.amount;
          })
          payload[property] = ingredients;
        } else {
          if (!this.skippedProperties.includes(property)) {
            payload[property] = importedItem[property];
          }
        }
      })

      this.loading = true;
      await apiSecured.post(`/ingame/items/item/`, payload).then(async (res) => {
        this.loading = false;
        this.showSuccessNotification(this.__('common.saved'));
        this.$forceUpdate();
        if (this.currentIndex < this.importedItems.length - 1) {
          this.currentIndex++;
        }
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    },
    prevItem() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    nextItem() {
      if (this.currentIndex < this.importedItems.length - 1) {
        this.currentIndex++;
      }
    },
    handleKeyUp(e) {
      switch (e.keyCode) {
        case 37:
          this.prevItem();
          break;

        case 39:
          this.nextItem();
          break;
      }
    },
  },
  created() {
    this.setPageTitle('Imported Items');
    this.refresh();
    window.addEventListener('keyup', this.handleKeyUp);
  },
  destroyed() {
    window.removeEventListener('keyup', this.handleKeyUp)
  }
}
</script>
